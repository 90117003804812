/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
    all,
    put,
    takeEvery,
    takeLeading,
    call,
    select,
} from 'redux-saga/effects';
import get from 'lodash/get';
import { push } from 'connected-react-router';
import KoddiAPI from 'api';
import { Auth0 } from 'modules/Auth0';
import SagaRegistry from 'redux-core/sagaRegistry';
import createSimpleSaga from 'utils/createSimpleSaga';
import { KoddiUser } from 'api/Admin/User';
import { UpdateKoddiUserInput } from 'api/Admin/User/User.types';
import {
    SESSION_STORAGE_KEY,
    THEME_STORAGE_KEY,
    AUTH0_ENABLED,
} from 'utils/constants';
import { LOGIN_ROUTE } from 'modules/constants/routes';
import { ErrorCauses } from 'redux-core/types';
import { RETURN_ROUTE_SESSION_KEY } from 'modules/Auth0/Auth0.const';
import {
    loginSuccess,
    loginError,
    logoutSuccess,
    logoutError,
    resetPasswordSuccess,
    resetPasswordError,
    resetPassword,
    forgotPasswordGetCodeSuccess,
    forgotPasswordGetCodeError,
    accountSetupSuccess,
    accountSetupError,
    accountSetup,
    logoutSubmit,
    confirmPassword,
    updateUserSuccess,
    updateUserError,
    logoutWithSSO,
    logoutWithSSOSubmit,
    loginWithSSOSuccess,
    login,
} from './actions';
import {
    AuthAction,
    LoginSubmitAction,
    LogoutSubmitAction,
    ForgotPasswordGetCodeAction,
    ResetPasswordSubmitAction,
    ForgotPasswordGetCodeSuccessAction,
    AccountSetupSubmitAction,
    LoginSuccessAction,
    AuthLocalStorageData,
} from './types';
import { selectEmail, selectAuthUser } from './selectors';

export function getSessionFromLocalStorage(): AuthLocalStorageData | null {
    const previousSession = localStorage.getItem(SESSION_STORAGE_KEY);
    if (previousSession) {
        return JSON.parse(previousSession);
    }
    return null;
}

export function getThemeFromLocalStorage(): AuthLocalStorageData | null {
    const previousSession = localStorage.getItem(THEME_STORAGE_KEY);
    if (previousSession) {
        return JSON.parse(previousSession);
    }
    return null;
}

export function getExpirationTime(expiresIn: number) {
    return +new Date() + expiresIn * 1000;
}

export function saveSessionToLocalStorage(
    token: string,
    email: string,
    refreshToken: string | null,
    expiresIn: number,
    expirationTime: number,
    loggedInWithCognito: boolean
) {
    localStorage.setItem(
        SESSION_STORAGE_KEY,
        JSON.stringify({
            token,
            email,
            refreshToken,
            expiresIn,
            expirationTime,
            loggedInWithCognito,
        })
    );
}

export function removeSessionFromLocalStorage() {
    localStorage.removeItem(SESSION_STORAGE_KEY);
}

/* Logs user in from token in localStoarge. Used when logging user in from Iframe. */
export function* loginFromStorage() {
    try {
        const previousSession = yield call(getSessionFromLocalStorage);
        if (previousSession) {
            const {
                token,
                email,
                refreshToken,
                expiresIn,
                expirationTime,
            }: AuthLocalStorageData = previousSession;

            yield call(KoddiAPI.signIn, token, expiresIn, expirationTime);

            const koddiUser = yield call(KoddiAPI.Admin.User.get, email);
            yield put(loginSuccess(koddiUser, token, refreshToken, expiresIn));
        } else {
            yield put(login());
        }
    } catch (e) {
        console.error(e.error);
    }
}

// Session Persistance Sagas
export function* persistSessionOnLoginSuccess({
    payload: {
        user,
        id_token,
        refresh_token,
        expires_in,
        expirationTime,
        persist,
        loggedInWithCognito,
    },
}: LoginSuccessAction) {
    try {
        if (id_token && persist) {
            yield call(
                saveSessionToLocalStorage,
                id_token,
                user.email,
                refresh_token,
                expires_in,
                expirationTime,
                loggedInWithCognito
            );
        }
    } catch (error) {
        console.error(e.error);
        yield;
    }
}

export function* watchLoginSuccess() {
    yield takeEvery(AuthAction.LOGIN_SUCCESS, persistSessionOnLoginSuccess);
}
// Login Sagas

export function* loginSubmitSaga({
    payload: { email, password },
}: LoginSubmitAction) {
    try {
        put(loginError(null));
        // Replace cognito SDK with internal /session/login endpoint to get Koddi specific id_token
        const loginResult = yield call(KoddiAPI.Auth.login, email, password);
        // Backend passes us the challengeName from Cognito if needed. Will be null if a simple login success is returned
        const challengeName = loginResult.challenge.name;

        switch (challengeName) {
            case 'NEW_PASSWORD_REQUIRED': {
                yield put(accountSetup(loginResult.challenge.session, email));
                break;
            }
            case 'PASSWORD_VERIFIER': {
                yield put(confirmPassword(email));
                break;
            }
            default: {
                // No challenge returned, user has successfully logged in
                // signIn method sets this.token on the KoddiAPI class
                const expiration = yield call(
                    getExpirationTime,
                    loginResult.token.expires_in
                );
                yield call(
                    KoddiAPI.signIn,
                    loginResult.token.id_token,
                    loginResult.token.expires_in,
                    expiration
                );
                const koddiUser = yield call(KoddiAPI.Admin.User.get, email);

                // Store token and email in localStorage and redirect to dashboard
                yield put(
                    loginSuccess(
                        koddiUser,
                        loginResult.token.id_token,
                        loginResult.token.refresh_token,
                        loginResult.token.expires_in,
                        expiration,
                        true
                    )
                );
                break;
            }
        }
    } catch (e) {
        console.error(e);
        if (e.code === 'PasswordResetRequiredException') {
            yield put(confirmPassword(email));
        }
        yield put(loginError(new Error(e.response.data.error)));
    }
}

export function* watchLoginSubmit() {
    yield takeLeading(AuthAction.LOGIN_SUBMIT, loginSubmitSaga);
}

// Logout Sagas
export function* returnToLoginRoute(action: LogoutAction) {
    const { loginKey, returnRoute } = action.payload;
    let loginRoute = LOGIN_ROUTE;
    if (returnRoute && loginKey) {
        loginRoute = `/${loginKey}/login?returnRoute=${returnRoute}`;
    }
    if (!loginKey && returnRoute) {
        loginRoute = `${LOGIN_ROUTE}?returnRoute=${returnRoute}`;
    }
    if (loginKey && !returnRoute) {
        loginRoute = `/${loginKey}/login`;
    }
    yield put(push(loginRoute));
}

export function* logoutSaga({ payload }: LogoutAction) {
    yield call(removeSessionFromLocalStorage);
    yield call(returnToLoginRoute, { payload });
}

export function* logoutApi({ payload }: LogoutSubmitAction) {
    yield call(KoddiAPI.Session.logout, payload.message);
    yield call(KoddiAPI.signOut);
    yield call(logoutSaga, { payload });
}

export const logoutSubmitSaga = createSimpleSaga(
    logoutApi,
    logoutSuccess,
    logoutError
);

export function* watchLogout() {
    yield takeEvery(AuthAction.LOGOUT, logoutSaga);
}

export function* watchLogoutSubmit() {
    yield takeEvery(AuthAction.LOGOUT_SUBMIT, logoutSubmitSaga);
}

// Login with Auth0 Sagas
export function* loginWithSSOSuccessSaga({
    payload: { member_group_id, auth0State },
}) {
    const {
        idToken,
        accessToken,
        user,
        expirationTime,
        expires_in,
    } = auth0State;
    try {
        yield call(
            KoddiAPI.Auth.postAuth0Token,
            member_group_id,
            idToken,
            accessToken
        );

        yield call(KoddiAPI.signIn, idToken, expires_in, expirationTime);

        localStorage.setItem(AUTH0_ENABLED, true);
        const koddiUser = yield call(KoddiAPI.Admin.User.get, user.email);
        yield put(
            loginSuccess(
                koddiUser,
                idToken,
                null,
                expires_in,
                expirationTime,
                true,
                false
            )
        );
    } catch (e) {
        console.error(e);
        yield put(logoutWithSSO());
        yield put(
            loginError(
                new Error('Sorry, we failed to log you in. Please try again.', {
                    cause: ErrorCauses.Auth0,
                })
            )
        );
    }
}

export function* watchLoginWithSSOSuccess() {
    yield takeEvery(AuthAction.LOGIN_WITH_SSO_SUCCESS, loginWithSSOSuccessSaga);
}

// Logout with Auth0 sagas

export function* logoutWithSSOSubmitSaga({ payload }: LogoutSubmitAction) {
    const { loginKey, returnRoute } = payload;
    let loginRoute = LOGIN_ROUTE;
    if (returnRoute && loginKey) {
        loginRoute = `/${loginKey}/login?returnRoute=${returnRoute}`;
    }
    if (!loginKey && returnRoute) {
        loginRoute = `${LOGIN_ROUTE}?returnRoute=${returnRoute}`;
    }
    if (loginKey && !returnRoute) {
        loginRoute = `/${loginKey}/login`;
    }
    const auth0ReturnUrl = `${window.location.origin}/#${loginRoute}`;
    try {
        yield call(removeSessionFromLocalStorage);
        if (returnRoute) {
            sessionStorage.setItem(RETURN_ROUTE_SESSION_KEY, returnRoute);
        }
        yield call(Auth0.logout, auth0ReturnUrl);
        yield call(KoddiAPI.signOut);
    } catch (err) {
        yield call(logoutError(err));
    }
}
export function* watchLogoutWithSSO() {
    yield takeEvery(AuthAction.LOGOUT_WITH_SSO, logoutSaga);
}
export function* watchLogoutWithSSOSubmit() {
    yield takeEvery(AuthAction.LOGOUT_WITH_SSO_SUBMIT, logoutWithSSOSubmitSaga);
}

// Account Setup Sagas

export function* accountSetupSubmitSaga({
    payload: { first_name, last_name, password, locale },
}: AccountSetupSubmitAction) {
    try {
        const session = yield select((state) =>
            get(state, 'auth.context.session', null)
        );
        const email = yield select((state) =>
            get(state, 'auth.context.email', null)
        );
        const newAccountResponse = yield call(KoddiAPI.Auth.setupNewAccount, {
            challenge: { session },
            email,
            password,
        });
        const {
            token: { id_token, refresh_token, expires_in },
        } = newAccountResponse;
        const expirationTime = yield call(getExpirationTime, expires_in);

        // sign in
        yield call(KoddiAPI.signIn, id_token, expires_in, expirationTime);

        // new Data gathered from form
        const newUserData: UpdateKoddiUserInput = {
            first_name,
            last_name,
            locale_id: locale.id,
        };

        // send info to DB
        yield call(KoddiAPI.Admin.User.updateBasicInfo, newUserData);

        // get user info after update
        const koddiUserByEmail: KoddiUser = yield call(
            KoddiAPI.Admin.User.get,
            email
        );

        // update Store with user
        yield put(accountSetupSuccess(koddiUserByEmail));

        yield put(
            loginSuccess(
                koddiUserByEmail,
                id_token,
                refresh_token,
                expires_in,
                expirationTime,
                true
            )
        );
    } catch (e) {
        yield put(accountSetupError(e.error));
    }
}

export function* watchAccountSetupSubmit() {
    yield takeLeading(AuthAction.ACCOUNT_SETUP_SUBMIT, accountSetupSubmitSaga);
}

// Forgot Password Get Code Sagas
export const forgotPasswordGetCodeApi = ({
    payload: { email },
}: ForgotPasswordGetCodeAction) => KoddiAPI.Auth.forgotPassword(email);

export const resetPasswordGetCodeApi = ({
    payload: { email },
}: ForgotPasswordGetCodeAction) => KoddiAPI.Auth.forgotPassword(email);

export const forgotPasswordGetCodeSaga = createSimpleSaga(
    forgotPasswordGetCodeApi,
    forgotPasswordGetCodeSuccess,
    forgotPasswordGetCodeError
);

export function* watchForgotPasswordGetCode() {
    yield takeLeading(
        AuthAction.FORGOT_PASSWORD_GET_CODE,
        forgotPasswordGetCodeSaga
    );
}

// Forgot Password Get Code Success Sagas

export function* forgotPasswordGetCodeSuccessSaga({
    payload: { codeDeliveryDetails },
}: ForgotPasswordGetCodeSuccessAction) {
    yield put(resetPassword(codeDeliveryDetails.username));
}

export function* watchForgotPasswordGetCodeSuccess() {
    yield takeLeading(
        AuthAction.FORGOT_PASSWORD_GET_CODE_SUCCESS,
        forgotPasswordGetCodeSuccessSaga
    );
}

// Reset Password Sagas

export const resetPasswordSubmitApi = ({
    payload: { email, code, password },
}: ResetPasswordSubmitAction) =>
    KoddiAPI.Auth.forgotPasswordSubmit(email, code, password);

export const resetPasswordSubmitSaga = createSimpleSaga(
    resetPasswordSubmitApi,
    resetPasswordSuccess,
    resetPasswordError
);

export function* watchResetPasswordSubmit() {
    yield takeLeading(
        AuthAction.RESET_PASSWORD_SUBMIT,
        resetPasswordSubmitSaga
    );
}

// Update User Saga

export function* updateUserApi() {
    const email = yield select(selectEmail);
    const user = yield call(KoddiAPI.Admin.User.get, email);
    return user;
}

export const updateUserSaga = createSimpleSaga(
    updateUserApi,
    updateUserSuccess,
    updateUserError
);

export function* watchUpdateUser() {
    yield takeLeading(AuthAction.UPDATE_USER, updateUserSaga);
}

// Refresh Session Sagas
export function* refreshSessionSubmit() {
    try {
        const auth0State = yield call(Auth0.getState);
        // This member group id must be for the user that is logged in, not the member group in context
        const { member_group_id } = yield select(selectAuthUser);
        if (auth0State) {
            yield put(loginWithSSOSuccess(member_group_id, auth0State));
        } else {
            throw Error('Auth0 could not refresh this session');
        }
    } catch (error) {
        yield put(logoutWithSSO());
    }
}

export function* watchRefreshSession() {
    yield takeEvery(AuthAction.REFRESH_SESSION, refreshSessionSubmit);
}

// Unauthorized Request Sagas

export function* unauthorizedRequestSaga(action: UnauthorizedRequestAction) {
    yield put(
        logoutSubmit(action.message, action.loginKey, action.returnRoute)
    );
    yield put(
        logoutWithSSOSubmit(action.message, action.loginKey, action.returnRoute)
    );
}

export function* watchUnauthorizedRequest() {
    yield takeEvery(AuthAction.UNAUTHORIZED_REQUEST, unauthorizedRequestSaga);
}

export function* watchLoginFromLocalStorage() {
    yield takeEvery(AuthAction.LOGIN_FROM_LOCAL_STORAGE, loginFromStorage);
}

// All Auth Sagas to be run

function* authSagas() {
    yield all([
        watchLoginWithSSOSuccess(),
        watchLoginSubmit(),
        watchLogoutSubmit(),
        watchLogoutWithSSOSubmit(),
        watchAccountSetupSubmit(),
        watchForgotPasswordGetCode(),
        watchForgotPasswordGetCodeSuccess(),
        watchResetPasswordSubmit(),
        watchRefreshSession(),
        watchUpdateUser(),
        watchUnauthorizedRequest(),
        watchLoginSuccess(),
        watchLogout(),
        watchLogoutWithSSO(),
        watchLoginFromLocalStorage(),
    ]);
}

SagaRegistry.registerSaga(authSagas);
