import { faEye, faEyeSlash, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { CIconButton } from 'app/koddi-components/src/_ChakraComponents/Buttons';
import { CFAIcon } from 'app/koddi-components/src/_ChakraComponents/Icons';
import React, { FunctionComponent, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import { Colors } from '../../../../ChakraTheme';

import { InputProps, InputTypes } from '../Input.types';
import { CInputBase } from '../InputBase.component';

export const CTextInput = ({ ...props }: InputProps): JSX.Element => {
    return <CInputBase {...props} type={InputTypes.Text} />;
};

export const CEmailInput = ({ ...props }: InputProps): JSX.Element => {
    return <CInputBase {...props} type={InputTypes.Email} />;
};

export const CNumberInput = ({ ...props }: InputProps): JSX.Element => {
    return <CInputBase {...props} type={InputTypes.Number} />;
};

export const CPasswordInput = ({ ...props }: InputProps): JSX.Element => {
    const [showPassword, setShowPassword] = useState(false);
    const { testId } = props;
    return (
        <CInputBase
            {...props}
            type={showPassword ? InputTypes.Text : InputTypes.Password}
            rightElement={
                <CIconButton
                    onClick={() => setShowPassword(!showPassword)}
                    testId={`${testId}--toggle-password`}
                >
                    {showPassword ? (
                        <CFAIcon icon={faEyeSlash} fill={Colors.inputIcon} />
                    ) : (
                        <CFAIcon icon={faEye} fill={Colors.inputIcon} />
                    )}
                </CIconButton>
            }
        />
    );
};

export const CSearchInput: FunctionComponent<
    InputProps & { debounceTime?: number }
> = ({ onChange, debounceTime = 0, ...props }): JSX.Element => {
    const handleChange = useDebouncedCallback((value?: string) => {
        onChange(value);
    }, debounceTime);

    return (
        <CTextInput
            hideError
            {...props}
            onChange={handleChange}
            leftElement={<CFAIcon icon={faSearch} fill={Colors.inputIcon} />}
        />
    );
};
