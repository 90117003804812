import React, { useCallback, useEffect, useState } from 'react';
import isSameMonth from 'date-fns/isSameMonth';
import isAfter from 'date-fns/isAfter';
import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';
import uniqueId from 'lodash/uniqueId';
import { useLocale } from 'koddi-components/LocaleProvider';
import SingleDatePicker from 'koddi-components/SingleDatePicker';
import { isWithinInterval, parseISO } from 'date-fns';
import { DateRangeCalendarsProps } from './DateRangeCalendars.types';
import { addAdditionalClassNames } from '../DateRangePicker.utils';
import * as Styled from './DateRangeCalendars.styled';

const DateRangeCalendars = ({
    startDate,
    endDate,
    startDateId,
    endDateId,
    onDayHover,
    hoveredDate,
    onFirstMonthDateChange,
    onSecondMonthDateChange,
    onChange,
    firstMonth,
    secondMonth,
    rangeIsValid,
    disallowFutureDates,
    disabledDates,
    v2 = false,
}: DateRangeCalendarsProps): JSX.Element => {
    const { locale } = useLocale();
    const [monthKeys, setMonthsKeys] = useState<{
        firstMonth: string;
        secondMonth: string;
    }>({
        firstMonth: uniqueId(),
        secondMonth: uniqueId(),
    });
    const createDayClassName = useCallback(
        (date: Date) => {
            return addAdditionalClassNames(
                date,
                startDate,
                endDate,
                hoveredDate,
                locale
            );
        },
        [startDate, endDate, hoveredDate, locale]
    );
    const firstMonthSelectedDate =
        startDate && isSameMonth(startDate, firstMonth) ? startDate : null;
    const secondMonthSelectedDate =
        startDate && isSameMonth(startDate, secondMonth) ? startDate : null;

    const handleFilterDate = useCallback(
        (date: Date) => {
            const isInbetweenDates = disabledDates?.some(([start, end]) => {
                const iswithin = isWithinInterval(date, {
                    start: parseISO(start),
                    end: parseISO(end),
                });
                return iswithin;
            });
            if (isInbetweenDates) return false;
            if (disallowFutureDates) return isAfter(new Date(), date);
            return true;
        },
        [disabledDates, disallowFutureDates]
    );
    const nextMonth = addMonths(new Date(), 1);
    const secondMonthIsNextMonth = isSameMonth(secondMonth, nextMonth);
    // Here we are generating new keys whenever the month or dates
    // change to force the date pickers to re-render correctly.
    useEffect(() => {
        setMonthsKeys({
            firstMonth: uniqueId(),
            secondMonth: uniqueId(),
        });
    }, [firstMonth, secondMonth, startDate, endDate]);

    return (
        <Styled.DateRangeCalendarsWrapper>
            <SingleDatePicker
                open
                key={monthKeys.firstMonth}
                name={startDateId}
                selected={firstMonthSelectedDate}
                startDate={startDate}
                endDate={endDate}
                onChange={(date) => onChange(new Date(date))}
                onMonthChange={onFirstMonthDateChange}
                openToDate={firstMonth}
                dayClassName={createDayClassName}
                onDayHover={onDayHover}
                customInput={null}
                value={startDate ? format(startDate, 'MM/dd/yyyy') : ''}
                nextMonthButtonDisabled
                usePortal={false}
                StylesComponent={Styled.DateRangePickerStyles}
                stylesComponentProps={{ rangeIsValid, v2 }}
                filterDate={handleFilterDate}
                v2={v2}
            />
            <SingleDatePicker
                open
                key={monthKeys.secondMonth}
                name={endDateId}
                selected={secondMonthSelectedDate}
                startDate={startDate}
                endDate={endDate}
                onChange={(date) => onChange(new Date(date))}
                onMonthChange={onSecondMonthDateChange}
                openToDate={secondMonth}
                dayClassName={createDayClassName}
                onDayHover={onDayHover}
                customInput={null}
                value={endDate ? format(endDate, 'MM/dd/yyyy') : ''}
                prevMonthButtonDisabled
                nextMonthButtonDisabled={
                    disallowFutureDates && secondMonthIsNextMonth
                }
                usePortal={false}
                StylesComponent={Styled.DateRangePickerStyles}
                stylesComponentProps={{ rangeIsValid, v2 }}
                filterDate={handleFilterDate}
                v2={v2}
            />
        </Styled.DateRangeCalendarsWrapper>
    );
};

export default DateRangeCalendars;
