import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import Select from 'react-select';
import SelectAsync from 'react-select/async';
import { useDebouncedCallback } from 'use-debounce/lib';
import { CFormError } from '../../../Typography';
import { OptionType, selectValue } from '../../Types';
import { VirtualizedMenuList } from './atoms/VirtualizedMenuList.atom';
import { useSelectField } from './SelectField.hook';
import { SelectFieldBaseProps } from './SelectField.types';
import { Opacity } from '../../../ChakraTheme';
import { OptionWithTooltip } from './atoms/OptionWithToolTip.atom';
import { GroupHeading } from './atoms/GroupHeading.atom';

export const CSelectFieldBase = <T extends selectValue = string | number>({
    testId,
    onChange,
    onClose,
    errorText,
    name,
    inputValue,
    hideError = false,
    isVirtualized = false,
    options,
    groupedOptions,
    isMulti,
    closeMenuOnSelect,
    components,
    isControlledInput,
    height,
    minWidth,

    isDisabled,
    placeholder,
    width,
    maxWidth,
    resetSearchOnSelection = false,
    shouldWrap,
    ...props
}: SelectFieldBaseProps<T>): JSX.Element => {
    const {
        handleChange,
        valueProp,
        styles,
        theme,
        defaultOptions,
        loadOptions,
        portal,
        open,
        setOpen,
        handleSearchChange,
        searchValue,
    } = useSelectField({
        onChange,
        options,
        groupedOptions,
        errorText,
        inputValue,
        isMulti,
        isControlledInput,
        height,
        resetSearchOnSelection,
        shouldWrap,
    });

    return (
        <Box
            data-test={testId}
            maxWidth={maxWidth}
            minWidth={`${minWidth ?? 150}px`}
            width={width}
            opacity={isDisabled ? Opacity.High : 'unset'}
            cursor={isDisabled ? 'not-allowed' : 'unset'}
        >
            {isVirtualized ? (
                <SelectAsync<OptionType>
                    {...props}
                    {...valueProp}
                    components={{
                        GroupHeading,
                        Option: OptionWithTooltip,
                        ...components,
                        MenuList: VirtualizedMenuList,
                    }}
                    closeMenuOnScroll
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions={defaultOptions}
                    onChange={handleChange}
                    onInputChange={handleSearchChange}
                    inputId={name}
                    inputValue={searchValue}
                    styles={styles}
                    theme={theme}
                    menuPortalTarget={portal}
                    isMulti={isMulti}
                    closeMenuOnSelect={isMulti ? false : closeMenuOnSelect}
                    hideSelectedOptions={false}
                    menuPlacement="auto"
                    isDisabled={isDisabled}
                    placeholder={placeholder}
                    menuIsOpen={open}
                    onMenuOpen={() => setOpen(true)}
                    onMenuClose={() => {
                        setOpen(false);
                        onClose?.();
                    }}
                />
            ) : (
                <Select<OptionType>
                    {...props}
                    {...valueProp}
                    components={{
                        GroupHeading,
                        Option: OptionWithTooltip,
                        ...components,
                    }}
                    closeMenuOnScroll
                    options={defaultOptions}
                    onChange={handleChange}
                    onInputChange={handleSearchChange}
                    inputId={name}
                    inputValue={searchValue}
                    styles={styles}
                    theme={theme}
                    menuPortalTarget={portal}
                    isMulti={isMulti}
                    closeMenuOnSelect={isMulti ? false : closeMenuOnSelect}
                    hideSelectedOptions={false}
                    menuPlacement="auto"
                    isDisabled={isDisabled}
                    placeholder={placeholder}
                    openMenuOnClick
                    menuIsOpen={open}
                    onMenuOpen={() => setOpen(true)}
                    onMenuClose={() => {
                        setOpen(false);
                        onClose?.();
                    }}
                />
            )}
            {!hideError && (
                <CFormError data-test={`${name}--error`} text={errorText} />
            )}
        </Box>
    );
};

export const CSelectFieldPaginated = <T extends selectValue = string | number>({
    testId,
    onChange,
    onClose,
    errorText,
    name,
    inputValue,
    hideError = false,
    groupedOptions,
    isMulti,
    closeMenuOnSelect,
    components,
    height,
    minWidth,
    dependsOn,

    isDisabled,
    placeholder,
    width,
    maxWidth,
    resetSearchOnSelection = false,
    shouldWrap,
    fetchOptions,
    trackedPagination,
    ...props
}: SelectFieldBaseProps<T> & {
    fetchOptions: (
        search: string | undefined,
        page: number,
        count?: number
    ) => Promise<OptionType[]>;
    trackedPagination?: {
        page: number;
        count: number;
    };
    dependsOn?: boolean;
}): JSX.Element => {
    const [options, setOptions] = useState<OptionType[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [initialized, setInitialized] = useState(false);
    const {
        handleChange,
        styles,
        theme,
        portal,
        open,
        setOpen,
        valueProp,
    } = useSelectField({
        onChange,
        options,
        groupedOptions,
        errorText,
        inputValue,
        isMulti,
        height,
        resetSearchOnSelection,
        shouldWrap,
        isControlledInput: true,
    });
    const handleSearch = async (
        inp: string | undefined,
        callback?: (arg0: OptionType<string | number>[]) => void
    ) => {
        setIsLoading(true);
        const data = await fetchOptions(inp, 0);
        setOptions(data);
        callback?.(data);
        setHasMore(data.length > 0);
        setIsLoading(false);
    };

    const fetchAndSetOptions = useCallback(
        async (count?: number, pageNumber?: number) => {
            if (!isLoading && hasMore) {
                setIsLoading(true);
                const data = await fetchOptions(
                    searchValue,
                    pageNumber ?? page,
                    count
                );
                if (data.length === 0) {
                    setHasMore(false);
                } else {
                    setOptions((prev) => {
                        const newOptions = data.filter(
                            (d) => !prev.find((p) => p.value === d.value)
                        );
                        return [...prev, ...newOptions];
                    });
                    setPage((prevPage) => prevPage + 1);
                }
                setIsLoading(false);
            }
        },
        [fetchOptions, hasMore, isLoading, page, searchValue]
    );

    useEffect(() => {
        if (trackedPagination && !initialized && dependsOn) {
            setInitialized(true);
            setPage(trackedPagination.page);
            fetchAndSetOptions(trackedPagination.count, trackedPagination.page);
        }
    }, [dependsOn, fetchAndSetOptions, initialized, trackedPagination]);

    const handleMenuOpen = async () => {
        if (options.length === 0) {
            await fetchAndSetOptions();
        }
        setOpen(true);
    };

    const debouncedChange = useDebouncedCallback(handleChange, 100);

    return (
        <Box
            data-test={testId}
            maxWidth={maxWidth}
            minWidth={`${minWidth ?? 150}px`}
            width={width}
            opacity={isDisabled ? Opacity.High : 'unset'}
            cursor={isDisabled ? 'not-allowed' : 'unset'}
        >
            <SelectAsync<OptionType>
                {...props}
                {...valueProp}
                defaultOptions={options}
                components={{
                    GroupHeading,
                    Option: OptionWithTooltip,
                    ...components,
                    MenuList: VirtualizedMenuList,
                }}
                isLoading={isLoading}
                cacheOptions
                loadOptions={handleSearch}
                options={options}
                onChange={debouncedChange}
                onMenuScrollToBottom={() => fetchAndSetOptions()}
                onInputChange={async (v, { action }) => {
                    if (action === 'input-change') {
                        setSearchValue(v);
                        if (v.length === 0 && !isLoading) {
                            await handleSearch(v);
                        }
                    }
                }}
                inputId={name}
                inputValue={searchValue}
                styles={styles}
                theme={theme}
                menuPortalTarget={portal}
                isMulti={isMulti}
                closeMenuOnSelect={isMulti ? false : closeMenuOnSelect}
                hideSelectedOptions={false}
                menuPlacement="auto"
                isDisabled={isDisabled}
                placeholder={placeholder}
                menuIsOpen={open}
                onMenuOpen={handleMenuOpen}
                onMenuClose={() => {
                    setOpen(false);
                    onClose?.();
                }}
            />
            {!hideError && (
                <CFormError data-test={`${name}--error`} text={errorText} />
            )}
        </Box>
    );
};
