import { DEPLOY_ENV } from 'app/types';

const isAzureEnv = window.DEPLOY_ENV === DEPLOY_ENV.AZURE;

const DEV_CONFIG = {
    audience: 'koddi-one-dev.us.auth0.com',
    domain: 'login.dev.koddi.io',
    clientId: isAzureEnv
        ? 'n0IaY35DIVGLbNxW4FCRAgyn7WkklLKH'
        : 'uUndSxRgCf9TsJ2U7cW1reH8EAva5WTG',
};

const UAT_CONFIG = {
    audience: 'koddi-one-uat.us.auth0.com',
    domain: 'login.uat.koddi.io',
    clientId: isAzureEnv
        ? 'LkBeilXUaDkT0K2qCcBDTOFynwJPVryD'
        : 'jvyoKECHWN9vN2ivuuB7E3qh2hUkKU9Z',
};

const PRODUCTION_CONFIG = {
    audience: 'koddi-one.us.auth0.com',
    domain: 'login.koddi.io',
    clientId: isAzureEnv
        ? 'KpafLHw5VgvzhVqAZeMgeabITXzkSYLQ'
        : 'YycfE8kh0BTp2Qt2u18QV0NOP6NDccGi',
};

const ENV_CONFIG_MAP: { [key: string]: any } = {
    local: DEV_CONFIG,
    development: DEV_CONFIG,
    uat: UAT_CONFIG,
    production: PRODUCTION_CONFIG,
    docker: DEV_CONFIG,
};

const ENV_CONFIG = ENV_CONFIG_MAP[window.TARGET_ENV];

export const AUTH0_CONFIG = {
    domain: ENV_CONFIG?.domain,
    clientId: ENV_CONFIG?.clientId,
    cacheLocation: 'localstorage' as 'localstorage' | 'memory',
    authorizationParams: {
        redirect_uri: window.location.origin,
        audience: `https://${ENV_CONFIG?.audience}/api/v2/`,
    },
    useRefreshTokens: true,
};

export const RETURN_ROUTE_SESSION_KEY = 'koddi_return_route';

export const USER_ATTEMPTED_LOGIN_KEY = 'user_attempted_login';
