import { Box, Checkbox, CheckboxGroup, Stack } from '@chakra-ui/react';
import React from 'react';
import { ColorSchemes, Spacing } from '../../../ChakraTheme';
import { CFormError } from '../../../Typography';
import { CCheckboxGroupProps } from './Checkbox.types';

export const CCheckboxGroup = ({
    options,
    onChange,
    inputValue,
    testId,
    errorText,
    hideError = false,
    isStacked = false,
}: CCheckboxGroupProps): JSX.Element => {
    return (
        <Box width="100%">
            <CheckboxGroup onChange={onChange} value={inputValue}>
                <Stack
                    direction={isStacked ? 'column' : 'row'}
                    gap={Spacing.SM}
                    flexWrap="wrap"
                >
                    {options.map(({ value, label }) => (
                        <Checkbox
                            data-test={`${testId}--${value}--option`}
                            value={value}
                            key={value}
                            colorScheme={ColorSchemes.primary}
                        >
                            {label}
                        </Checkbox>
                    ))}
                </Stack>
            </CheckboxGroup>
            {!hideError && (
                <CFormError data-test={`${testId}--error`} text={errorText} />
            )}
        </Box>
    );
};
