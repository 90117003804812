import axios from 'axios';
import {
    ForgotPasswordData,
    SetupNewAccountResponse,
    LoginResult,
    NewAccountPayload,
} from './Auth.types';
/**
 * The Koddi Auth API.
 */
class KoddiAuthAPI {
    public login = async (
        username: string,
        password: string
    ): Promise<LoginResult> => {
        const result = await axios.post(`${window.API_ROUTE}v1/session/login`, {
            email: username,
            password,
        });
        return result.data.result;
    };

    public setupNewAccount = async (
        data: NewAccountPayload
    ): Promise<SetupNewAccountResponse> => {
        const response = await axios.patch(
            `${window.API_ROUTE}v1/session/set_permanent_password`,
            data
        );
        return response.data.result;
    };

    public forgotPassword = async (
        email: string
    ): Promise<ForgotPasswordData> => {
        await axios.put(`${window.API_ROUTE}v1/users/reset_password`, {
            email,
        });
        return {
            username: email,
            CodeDeliveryDetails: {
                AttributeName: '',
                DeliveryMedium: 'EMAIL',
                Destination: email,
            },
        };
    };

    // renaming method for a different use case, so the name makes sense for the use
    public resetPassword = this.forgotPassword;

    public forgotPasswordSubmit = async (
        email: string,
        confirmation_code: string,
        password: string
    ): Promise<void> => {
        await axios.patch(
            `${window.API_ROUTE}v1/session/confirm_forgot_password`,
            {
                email,
                confirmation_code,
                password,
            }
        );
    };

    // SSO Auth0 Requests

    public postAuth0Token = async (
        member_group_id: number,
        id_token: string,
        access_token: string
    ): Promise<void> => {
        await axios.post(`${window.API_ROUTE}v1/sso/login`, {
            member_group_id,
            id_token,
            access_token,
        });
    };
}

export default KoddiAuthAPI;
